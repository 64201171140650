import { useCallback } from 'react'
import {
    usePostUsersForgotPassword,
    usePostUsersForgotPasswordVerify,
} from '@dostavkee/contracts-console/hooks'
import { getCountdownDiffSeconds } from '@dostavkee/helpers'
import * as Sentry from '@sentry/react'
import { useNavigate } from '@tanstack/react-router'
import { useAtom } from 'jotai'
import { atomWithStorage, createJSONStorage } from 'jotai/utils'
import { toast } from 'react-toastify'

import { EmailConfirmationFormValues } from '@/app/routes/_auth/login/reset-password/-ui'
import { ResetPasswordFormValues } from '@/app/routes/_auth/login/reset-password/confirm/-ui'

import { ERROR_CODE, ERROR_CODE_MESSAGE } from '../../config'
import { TIMEOUT_RESEND_EMAIL_CODE_SECONDS } from '../../config/constants'
import { useActiveModule } from './use-active-module'
import { useAuth } from './use-auth'

export interface IPasswordResetData {
    code_uuid?: string
    email?: string
    timestampEmailRequested?: number
}

const persistStorage = createJSONStorage<IPasswordResetData | undefined>(() => localStorage)
const passwordResetDataAtom = atomWithStorage<IPasswordResetData | undefined>(
    '@dostavkee/reset-password',
    undefined,
    persistStorage
)

export const usePasswordReset = () => {
    const [passwordResetData, setPasswordResetData] = useAtom(passwordResetDataAtom)
    const { setAuth } = useAuth()
    const navigate = useNavigate()
    const email = passwordResetData?.email
    const timestampEmailRequested = passwordResetData?.timestampEmailRequested

    const { mutateAsync: forgotPassword } = usePostUsersForgotPassword()

    const handleSendVerifyCode = useCallback(
        async (data: EmailConfirmationFormValues) => {
            const reminder = getCountdownDiffSeconds(
                passwordResetData?.timestampEmailRequested,
                TIMEOUT_RESEND_EMAIL_CODE_SECONDS
            )

            if (reminder > 0) {
                toast.error(`Повторная отправка кода возможна через ${reminder} секунд`)

                return
            }

            const toastId = toast.loading('Отправка кода...')

            return forgotPassword(
                {
                    data,
                },
                {
                    onSuccess: ({ code_id }) => {
                        toast.success(`Мы отправили код вам на почту: ${data.email}`)

                        setPasswordResetData(() => ({
                            email: data.email,
                            code_uuid: code_id,
                            timestampEmailRequested: Math.floor(Date.now() / 1000),
                        }))

                        navigate({
                            to: '/login/reset-password/confirm',
                            mask: { to: '/login/reset-password' },
                        })
                    },
                    onError: (error) => {
                        if (error.error_code === ERROR_CODE.USER_NOT_FOUND) {
                            toast.error(ERROR_CODE_MESSAGE.get(error.error_code))

                            return
                        }

                        if (error.error_message) {
                            toast.error(error.error_message)

                            return
                        }

                        toast.error('Неизвестная ошибка')

                        Sentry.captureException(error)
                    },
                    onSettled: () => {
                        toast.dismiss(toastId)
                    },
                }
            )
        },
        [forgotPassword, navigate, passwordResetData?.timestampEmailRequested, setPasswordResetData]
    )

    const { mutateAsync: forgotPasswordVerify } = usePostUsersForgotPasswordVerify()
    const { activeModule } = useActiveModule()

    const handlePasswordReset = useCallback(
        async (data: ResetPasswordFormValues) => {
            const toastId = toast.loading('Отправка кода...')

            return forgotPasswordVerify(
                {
                    data: {
                        code_uuid: passwordResetData?.code_uuid!,
                        email: passwordResetData?.email!,
                        code: data.verify_code,
                        password: data.password,
                    },
                },
                {
                    onSuccess: ({ access_token }) => {
                        toast.success(`Пароль изменён`)

                        setPasswordResetData(undefined)

                        setAuth({ access_token })

                        navigate({ to: `/${activeModule}` })
                    },
                    onError: (error) => {
                        if (error.error_code === ERROR_CODE.USER_VERIFICATION_CODE_DOESNT_EXISTS) {
                            toast.error(ERROR_CODE_MESSAGE.get(error.error_code))

                            return
                        }

                        if (error.error_message) {
                            toast.error(error.error_message)

                            return
                        }

                        toast.error('Неизвестная ошибка')

                        Sentry.captureException(error)
                    },
                    onSettled: () => {
                        toast.dismiss(toastId)
                    },
                }
            )
        },
        [
            activeModule,
            forgotPasswordVerify,
            navigate,
            passwordResetData?.code_uuid,
            passwordResetData?.email,
            setAuth,
            setPasswordResetData,
        ]
    )

    return { handleSendVerifyCode, handlePasswordReset, email, timestampEmailRequested }
}
