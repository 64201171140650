import React, { useMemo } from 'react'
import { ServiceOrder, ServiceOrderStage } from '@dostavkee/contracts-console/entities'
import { GetOrderPublicInfoResponse } from '@dostavkee/contracts-external/entities'
import { IconCheck, IconClose } from '@dostavkee/web-ui'
import { clsx } from 'clsx'

import { STAGE_TO_TEXT } from '@/shell/shared/config'

import { PROGRESS_STATUS_FLOW, PROGRESS_STATUS_WITH_FLOW } from '../../model/constants'
import styles from './progress-status.module.scss'
import { ProgressStatusTimer } from './progress-status-timer'

interface ProgressStatusProps {
    order: ServiceOrder | GetOrderPublicInfoResponse
    variant?: 'solid'
}

export const ProgressStatus = ({ order, variant }: ProgressStatusProps) => {
    const activeIndex = useMemo(() => PROGRESS_STATUS_FLOW.indexOf(order.stage), [order.stage])

    return (
        <div
            className={clsx(styles['progress-status'], {
                [styles['progress-status--solid']]: variant === 'solid',
            })}
        >
            <div className={styles['progress-status__text']}>
                {STAGE_TO_TEXT.get(order.stage)}
                {order.stage === ServiceOrderStage.OrderStageCourierAccepted && (
                    <div className={styles['progress-status__timer']}>
                        <ProgressStatusTimer time={order.courier?.arrival_time} />
                    </div>
                )}
                {order.stage === ServiceOrderStage.OrderStageCourierPickedUp && (
                    <div className={styles['progress-status__timer']}>
                        <ProgressStatusTimer time={order.courier?.delivery_time} />
                    </div>
                )}
                {order.stage === ServiceOrderStage.OrderStageOrderCompleted && (
                    <IconCheck color='var(--information-medium-earth-medium)' size={20} />
                )}
                {[
                    ServiceOrderStage.OrderStageClientCanceled,
                    ServiceOrderStage.OrderStageCourierCanceled,
                    ServiceOrderStage.OrderStageCanceledByTimeout,
                    ServiceOrderStage.OrderStageClientArchived,
                ].includes(order.stage) && (
                    <IconClose color='var(--extensions-background-error)' size={20} />
                )}
            </div>
            {PROGRESS_STATUS_WITH_FLOW.includes(order.stage) && (
                <div className={styles['progress-status__items']}>
                    {activeIndex === 0 ? (
                        <div
                            className={clsx(
                                styles['progress-status__item'],
                                styles['progress-status__item--process']
                            )}
                        />
                    ) : (
                        PROGRESS_STATUS_FLOW.filter(
                            (status) => status !== ServiceOrderStage.OrderStageSearching
                        ).map((status, index) => (
                            <div
                                key={status}
                                className={clsx(styles['progress-status__item'], {
                                    [styles['progress-status__item--completed']]:
                                        index < activeIndex,
                                    [styles['progress-status__item--process']]:
                                        index === activeIndex,
                                    [styles['progress-status__item--future']]: index > activeIndex,
                                })}
                            />
                        ))
                    )}
                </div>
            )}
        </div>
    )
}
