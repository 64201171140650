import React, { FC } from 'react'
import clsx from 'clsx'

import styles from './chip.module.scss'

export enum ChipVariant {
    Info = 'info',
    InfoDeep = 'info-deep',
    Success = 'success',
    SuccessDeep = 'success-deep',
    Warning = 'warning',
    Secondary = 'secondary',
    SecondaryDeep = 'secondary-deep',
    SecondaryLight = 'secondary-light',
    Error = 'error',
    ErrorDeep = 'error-deep',
    ErrorLight = 'error-light',
}

export interface ChipProps extends React.HTMLAttributes<HTMLSpanElement> {
    children?: React.ReactNode
    variant?: ChipVariant
}

export const Chip: FC<ChipProps> = ({ children, variant = ChipVariant.Info, ...props }) => {
    return (
        <span
            className={clsx(styles['chip'], {
                [styles[`variant-${variant}`]]: true,
            })}
            {...props}
        >
            {children}
        </span>
    )
}

Chip.displayName = 'Chip'
