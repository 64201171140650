import React, { FC, lazy, Suspense } from 'react'
import { useScrollLock } from '@dostavkee/react-hooks'
import clsx from 'clsx'
import { createPortal } from 'react-dom'

import styles from './modal.module.scss'
import { ModalBody } from './modal-body'
import { ModalFooter } from './modal-footer'
import { ModalHeader } from './modal-header'

const FocusLock = lazy(async () => import('react-focus-lock'))

export interface ModalProps {
    children: React.ReactNode
    isOpen: boolean
    onClose?: () => void
    inject?: HTMLElement
    size?: 'medium' | 'large' | 'small'
    className?: string
}

export interface ModalComposition {
    Header: typeof ModalHeader
    Body: typeof ModalBody
    Footer: typeof ModalFooter
}

export const Modal: FC<ModalProps> & ModalComposition = ({
    isOpen,
    onClose,
    children,
    inject = document.body,
    size = 'medium',
    className,
}: ModalProps) => {
    useScrollLock({ autoLock: isOpen, lockTarget: document.body })

    if (!isOpen) {
        return null
    }

    return createPortal(
        <div className={styles['modal-wrapper']}>
            <div className={styles['modal__backdrop']} onClick={onClose} />
            <section
                className={clsx(className, {
                    [styles['modal']]: true,
                    [styles[`modal--size-${size}`]]: true,
                })}
            >
                <Suspense>
                    <FocusLock autoFocus={false}>{children}</FocusLock>
                </Suspense>
            </section>
        </div>,
        inject
    )
}

Modal.displayName = 'Modal'

Modal.Header = ModalHeader

Modal.Body = ModalBody

Modal.Footer = ModalFooter
