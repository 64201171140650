import { usePostOrdersReport } from '@dostavkee/contracts-console/hooks'
import * as Sentry from '@sentry/react'
import dayjs from 'dayjs'
import { toast } from 'react-toastify'

import { ERROR_CODE, ERROR_CODE_MESSAGE } from '@/shell/shared/config'

export const useOrdersDownloadReport = () => {
    const { mutate, isPending } = usePostOrdersReport()

    const downloadReport = (start_date?: string, end_date?: string, address_id?: string) => {
        mutate(
            {
                params: {
                    start_date: start_date,
                    end_date: end_date,
                    address_id: address_id,
                    timezone_offset_minutes: dayjs().utcOffset(),
                },
            },
            {
                onSuccess: (response) => {
                    const url = window.URL.createObjectURL(new Blob([response]))
                    const link = document.createElement('a')
                    link.href = url
                    const startPeriod = start_date && start_date.replaceAll('-', '.')
                    const endPeriod = end_date && end_date.replaceAll('-', '.')
                    const isDiffDate = start_date !== end_date
                    let fileName = 'Отчет по заказам'

                    if (startPeriod && endPeriod) {
                        fileName = isDiffDate
                            ? `${fileName} от ${startPeriod} до ${endPeriod}`
                            : `${fileName} от ${startPeriod}`
                    }

                    link.download = `${fileName}.csv`
                    link.click()
                    link.remove()
                    window.URL.revokeObjectURL(url)

                    toast.success('Отчёт по завершённым заказам сформирован')
                },
                onError: (error) => {
                    if (error?.error_code === ERROR_CODE.ORDERS_NOT_FOUND) {
                        toast.error(ERROR_CODE_MESSAGE.get(error.error_code))
                    } else {
                        toast.error('Ошибка при формировании отчёта. Неизвестная ошибка')

                        Sentry.captureException(error)
                    }
                },
            }
        )
    }

    return {
        downloadReport,
        isPending,
    }
}
