import { moneyFormatter } from '@dostavkee/helpers'

export enum ERROR_CODE {
    USER_ALREADY_EXIST = 1001,
    USER_NOT_FOUND = 1002,
    USER_REFRESH_TOKEN_COOKIE_NOT_FOUND = 1003,
    USER_REFRESH_TOKEN_EXPIRED = 1004,
    USER_EMAIL_CODE_DOESNT_EXISTS = 1005,
    USER_ALREADY_HAS_COMPANY = 1006,
    USER_HAS_NO_RELATION_WITH_THE_COMPANY = 1007,
    USER_VERIFICATION_CODE_DOESNT_EXISTS = 1008,

    CITY_ALREADY_EXISTS = 2001,

    COMPANY_ALREADY_EXISTS = 3001,
    COMPANY_NOT_FOUND = 3002,
    COMPANY_ALREADY_SET_CATEGORY = 3003,
    COMPANY_UPDATE_PROHIBITED = 3004,
    COMPANY_NOT_VERIFIED = 3006,

    CATEGORY_ALREADY_EXISTS = 4001,
    CATEGORY_NOT_FOUND = 4002,

    ADDRESS_NOT_FOUND = 5001,

    VALIDATION_ERROR = 6000,
    ORDER_ALREADY_EXISTS = 6001,
    ORDER_HAS_NO_RELATION_WITH_THE_USER = 6002,
    ORDER_NOT_FOUND = 6003,
    DRIVEE_CREATION_ORDER = 6004,
    ORDER_INAPPROPRIATE_EVENT = 6005,
    INSUFFICIENT_FUNDS_TO_CREATE_AN_ORDER = 6006,
    ACTIVE_ORDERS_LIMIT_EXCEEDED_ERROR_MESSAGE = 6007,
    ORDER_NO_ONE_IS_AVAILABLE_ERROR_CODE = 6008,
    ORDER_UNATTRACTIVE_PRICE_ERROR_CODE = 6009,
    ORDER_OFFER_A_HIGHER_FARE_ERROR_CODE = 6010,
    ORDER_REQUEST_IS_NOT_ACCEPTED_ERROR_CODE = 6011,
    ORDER_EDIT_COOLDOWN_ERROR_CODE = 6013,
    ORDER_MIN_AMOUNT_ERROR_CODE = 6014,

    ORDER_NOT_ACTIVE_ERROR_CODE = 6015,
    ORDER_NOT_IN_PROCESS_ERROR_CODE = 6016,

    ORDERS_NOT_FOUND = 6017,

    INVOICE_CREATION = 7001,

    EMPLOYEE_ALREADY_EXISTS_ERROR_CODE = 8000,
    EMPLOYEE_DRIVEE_USER_NOT_FOUND_ERROR_CODE = 8001,
    EMPLOYEE_DRIVEE_USER_ALREADY_BOUND_TO_COMPANY_ERROR_CODE = 8002,

    CORP_COMPANY_CARD_UNBIND_ACTIVE_ORDERS_EXISTS_ERROR_CODE = 10_001,
    CORP_COMPANY_CARD_ALREADY_EXISTS_ERROR_CODE = 10_002,
}

export const ERROR_CODE_MESSAGE = new Map<number, string>([
    [ERROR_CODE.USER_ALREADY_EXIST, 'Пользователь с этой почтой уже зарегистрирован'],
    [ERROR_CODE.USER_NOT_FOUND, 'Почта или пароль введены неправильно'],
    [ERROR_CODE.USER_REFRESH_TOKEN_EXPIRED, 'Сессия истекла, пожалуйста, войдите в систему снова'],
    [ERROR_CODE.USER_EMAIL_CODE_DOESNT_EXISTS, 'Неправильный код подтверждения'],
    [ERROR_CODE.USER_ALREADY_HAS_COMPANY, 'Пользователь с этой компанией уже зарегистрирован'],
    [ERROR_CODE.USER_VERIFICATION_CODE_DOESNT_EXISTS, 'Неправильный код подтверждения'],
    [ERROR_CODE.CITY_ALREADY_EXISTS, 'Город с таким названием уже зарегистрирован'],
    [ERROR_CODE.COMPANY_ALREADY_EXISTS, 'Эта компания уже зарегистрирована'],
    [ERROR_CODE.COMPANY_NOT_FOUND, 'Компания не найдена'],
    [ERROR_CODE.ADDRESS_NOT_FOUND, 'Адрес не найден'],
    [ERROR_CODE.INSUFFICIENT_FUNDS_TO_CREATE_AN_ORDER, 'Недостаточно денег на счёте'],
    [
        ERROR_CODE.ACTIVE_ORDERS_LIMIT_EXCEEDED_ERROR_MESSAGE,
        'У вас 10 активных заказов. Дождитесь завершения одного из них, чтобы создать новый',
    ],
    [
        ERROR_CODE.COMPANY_NOT_VERIFIED,
        `Внесите сумму от ${moneyFormatter(__GLOBAL__.VITE_PAYMENT_MIN_AMOUNT)} по расчётному счёту и добавьте хотя бы один адрес доставки`,
    ],
    [ERROR_CODE.ORDER_MIN_AMOUNT_ERROR_CODE, 'Ошибка минимальной суммы заказа'],
    [
        ERROR_CODE.ORDER_UNATTRACTIVE_PRICE_ERROR_CODE,
        'Сейчас нет свободных водителей. Попробуйте ещё раз позже',
    ],
    [
        ERROR_CODE.ORDER_NO_ONE_IS_AVAILABLE_ERROR_CODE,
        'Не удалось создать заказ. Попробуйте повторить позже',
    ],
    [
        ERROR_CODE.ORDER_OFFER_A_HIGHER_FARE_ERROR_CODE,
        'Цена не привлекательна для курьеров. Рекомендуем повысить цену',
    ],
    [
        ERROR_CODE.ORDER_REQUEST_IS_NOT_ACCEPTED_ERROR_CODE,
        'Не удалось создать заказ. Попробуйте повторить позже',
    ],
    [ERROR_CODE.INVOICE_CREATION, 'Что-то пошло не так. Обратитесь в Службу поддержки'],
    [ERROR_CODE.EMPLOYEE_ALREADY_EXISTS_ERROR_CODE, 'Сотрудник уже существует'],
    [
        ERROR_CODE.EMPLOYEE_DRIVEE_USER_NOT_FOUND_ERROR_CODE,
        'Пользователя с таким номером нет в Drivee',
    ],
    [
        ERROR_CODE.EMPLOYEE_DRIVEE_USER_ALREADY_BOUND_TO_COMPANY_ERROR_CODE,
        'Работник уже связан с компанией.',
    ],
    [
        ERROR_CODE.CORP_COMPANY_CARD_UNBIND_ACTIVE_ORDERS_EXISTS_ERROR_CODE,
        'Нельзя удалить карту, если есть активные заказы',
    ],
    [ERROR_CODE.CORP_COMPANY_CARD_ALREADY_EXISTS_ERROR_CODE, 'Карта уже привязана к компании'],
    [ERROR_CODE.VALIDATION_ERROR, 'Исправьте ошибки и попробуйте снова'],
    [
        ERROR_CODE.ORDER_EDIT_COOLDOWN_ERROR_CODE,
        'Цена может быть повышена раз в 15 секунд. Пожалуйста, подождите и попробуйте снова',
    ],
    [
        ERROR_CODE.ORDERS_NOT_FOUND,
        'Нет заказов по указанным параметрам. Измените параметры поиска и повторите снова',
    ],
])
