import { DomainReportDocTypeString } from '@dostavkee/contracts-console/entities'

export const COURIER_TYPE_TO_TEXT: { [key: string]: string } = {
    walking: 'Вело/пеший курьер',
    auto: 'Автокурьер',
}

export const TIMEOUT_RESEND_EMAIL_CODE_SECONDS = 60

export const ORDER_FORM_PERSISTED_STORAGE_KEY = '@dostavkee/order-form'

export const ORDER_FORM_PERSISTED_SENDER_STORAGE_KEY = '@dostavkee/order-form-sender'

export const REFRESH_INTERVAL_ACTIVE_ORDERS =
    typeof __GLOBAL__.VITE_REFRESH_INTERVAL_ACTIVE_ORDERS === 'string'
        ? Number.parseInt(__GLOBAL__.VITE_REFRESH_INTERVAL_ACTIVE_ORDERS, 10)
        : 4000

export const REFRESH_INTERVAL_BALANCE =
    typeof __GLOBAL__.VITE_REFRESH_INTERVAL_BALANCE === 'string'
        ? Number.parseInt(__GLOBAL__.VITE_REFRESH_INTERVAL_BALANCE, 10)
        : 10_000

export const REFRESH_INTERVAL_LOCATION =
    typeof __GLOBAL__.VITE_REFRESH_INTERVAL_LOCATION === 'string'
        ? Number.parseInt(__GLOBAL__.VITE_REFRESH_INTERVAL_LOCATION, 10)
        : 4000

export const REFRESH_INTERVAL_COURIERS =
    typeof __GLOBAL__.VITE_REFRESH_INTERVAL_COURIERS === 'string'
        ? Number.parseInt(__GLOBAL__.VITE_REFRESH_INTERVAL_COURIERS, 10)
        : 8000

export const ORDER_UPDATE_AMOUNT_STEP = __GLOBAL__.VITE_ORDER_UPDATE_AMOUNT_STEP
    ? Number.parseInt(__GLOBAL__.VITE_ORDER_UPDATE_AMOUNT_STEP, 10)
    : 20

export const DOCUMENT_TYPE_TO_TEXT = new Map<DomainReportDocTypeString, string>([
    [DomainReportDocTypeString.ReportDocTypeActStr, 'Акт'],
    [DomainReportDocTypeString.ReportDocTypeAgentReportStr, 'Отчёт агента'],
    [DomainReportDocTypeString.ReportDocTypeInvoiceStr, 'Счёт-фактура'],
])

export const PIN_ICON_WIDTH = 36

export const PIN_ICON_HEIGHT = 58

export const PAGE_SIZE = 10
