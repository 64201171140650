import React, { Suspense, useEffect, useState } from 'react'
import { getUsersProfileWebim } from '@dostavkee/contracts-console/api'
import { AXIOS_INSTANCE } from '@dostavkee/contracts-console/axios-client'
import {
    getUsersProfileQueryKey,
    usePostUsersAuthRefresh,
} from '@dostavkee/contracts-console/hooks'
import { AXIOS_INSTANCE as AXIOS_INSTANCE_EXTERNAL } from '@dostavkee/contracts-external/axios-client'
import { Spinner } from '@dostavkee/web-ui'
import { useQueryClient } from '@tanstack/react-query'
import { createRootRoute, Outlet, ScrollRestoration } from '@tanstack/react-router'
import { AxiosError } from 'axios'
import createAuthRefreshInterceptor from 'axios-auth-refresh'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { useAtom } from 'jotai'
import { Flip, toast, ToastContainer } from 'react-toastify'

import 'dayjs/locale/ru'

import {
    ERROR_CODE,
    ERROR_CODE_MESSAGE,
    MODULE_NAME,
    ORDER_FORM_PERSISTED_SENDER_STORAGE_KEY,
    ORDER_FORM_PERSISTED_STORAGE_KEY,
} from '@/shell/shared/config'
import { authAtom, useActiveModule, useAuth } from '@/shell/shared/model'
import { NotFound } from '@/shell/shared/ui'
import { Devtools } from '@/shell/shared/ui/devtools'

import { ModuleThemeSwitcher } from '../config/module-theme-switcher'

dayjs.extend(customParseFormat)
dayjs.locale('ru')

export const Route = createRootRoute({
    component: Root,
    notFoundComponent: NotFound,
    validateSearch: (
        search: Record<string, unknown>
    ): {
        utm_source?: MODULE_NAME
    } => {
        return {
            utm_source: search?.utm_source as MODULE_NAME,
        }
    },
})

/**
 * Это временное решение для того, чтобы очистить кэш запросов
 * Добавляем параметр cache_version для всех запросов
 * Это позволяет нам очищать кэш запросов, если он не соответствует версии
 *
 * TODO: Удалить после нового года 2025
 */
AXIOS_INSTANCE.interceptors.request.use((config) => {
    config.params = {
        ...(config?.params ?? {}),
        cache_version: 1,
    }

    return config
})

const RootView = () => {
    const [isInitialized, setIsInitialized] = useState<boolean>(false)
    const [authStorage] = useAtom(authAtom)

    useEffect(() => {
        /**
         * Устанавливаем базовый URL для axios перед тем, как приложение будет инициализировано
         */
        AXIOS_INSTANCE.defaults.baseURL = __GLOBAL__.VITE_API_URL
        AXIOS_INSTANCE_EXTERNAL.defaults.baseURL = __GLOBAL__.VITE_EXTERNAL_API_URL
        AXIOS_INSTANCE.defaults.timeout = __GLOBAL__.VITE_API_TIMEOUT
            ? Number(__GLOBAL__.VITE_API_TIMEOUT)
            : 10_000
    }, [])

    useEffect(() => {
        if (authStorage?.access_token) {
            AXIOS_INSTANCE.defaults.headers.Authorization = `Bearer ${authStorage?.access_token}`
        }
    }, [authStorage?.access_token])

    const { auth, setAuth, profile, setRegisterUser, isAuthenticated, isFullyRegistered } =
        useAuth()

    const [isInitWebimVisitor, setIsInitWebimVisitor] = useState(false)

    const queryClient = useQueryClient()

    const { utm_source } = Route.useSearch()
    const { setActiveModule } = useActiveModule()

    const { mutate: mutateRefreshToken } = usePostUsersAuthRefresh()

    /**
     * Инициализация приложения
     */
    useEffect(() => {
        /**
         * Устанавливаем активный модуль из utm_source если он есть
         */
        if (utm_source) {
            setActiveModule(utm_source)
        }
        /**
         * Создаем интерсептор для обновления токена
         */
        createAuthRefreshInterceptor(
            AXIOS_INSTANCE,
            async (failedRequest: any) => {
                const auth = localStorage.getItem('@dostavkee/auth')
                if (!auth || auth === 'undefined') {
                    return Promise.reject()
                }

                mutateRefreshToken({} as unknown as undefined, {
                    async onSuccess(response) {
                        setAuth({
                            access_token: response.access_token,
                        })

                        AXIOS_INSTANCE.defaults.headers.Authorization = `Bearer ${response.access_token}`

                        failedRequest.response.config.headers['Authorization'] =
                            'Bearer ' + response.access_token

                        return Promise.resolve()
                    },
                    async onError(error) {
                        console.error(error)

                        setAuth(undefined)

                        queryClient.clear()

                        AXIOS_INSTANCE.defaults.headers.Authorization = ''

                        setRegisterUser(undefined)

                        /**
                         * Clear persisted order form data
                         */
                        localStorage.removeItem(ORDER_FORM_PERSISTED_STORAGE_KEY)
                        localStorage.removeItem(ORDER_FORM_PERSISTED_SENDER_STORAGE_KEY)

                        toast.error(ERROR_CODE_MESSAGE.get(ERROR_CODE.USER_REFRESH_TOKEN_EXPIRED))

                        return Promise.reject()
                    },
                })
            },
            {
                pauseInstanceWhileRefreshing: false,
                interceptNetworkError: false,
                statusCodes: [401],
            }
        )

        /**
         * Ставим флаг инициализации приложения о том, что приложение готово к работе
         */
        setIsInitialized(true)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    /**
     * Получаем профиль пользователя после авторизации
     * Если пользователь авторизован и профиль не загружен, то загружаем его
     */
    useEffect(() => {
        if (isInitialized && auth?.access_token && !profile?.user?.id) {
            queryClient.invalidateQueries({
                queryKey: getUsersProfileQueryKey(),
            })
        }
    }, [auth?.access_token, isInitialized, profile, queryClient])

    useEffect(() => {
        console.info(`Version: ${APP_VERSION}`)

        /**
         * Устанавливаем настройки для всех запросов tanstack query
         * Если запрос возвращает ошибку 401, то не будем пытаться его повторить,
         * а сразу обрабатываем ошибку для обновления токена
         */
        queryClient.setDefaultOptions({
            queries: {
                retry(failureCount, error) {
                    if (error instanceof AxiosError && error.response?.status === 401) {
                        return false
                    }

                    return failureCount < 3
                },
            },
        })
    }, [queryClient])

    /**
     * Заполняем webim_visitor после авторизации,
     * если пользователь полностью зарегистрирован
     */
    useEffect(() => {
        if (
            isAuthenticated &&
            isFullyRegistered &&
            profile?.user?.company &&
            !webim_visitor?.fields?.id &&
            !isInitWebimVisitor
        ) {
            setIsInitWebimVisitor(true)
            getUsersProfileWebim().then((response) => {
                webim_visitor = {
                    fields: {
                        display_name: response?.fields?.name,
                        email: response?.fields?.email,
                        id: response?.fields?.id,
                        info: response?.fields?.info,
                        phone: response?.fields?.phone,
                    },
                    hash: response.hash,
                }
                if (typeof webim?.api?.onProvidedVisitorChanged == 'function') {
                    webim.api.onProvidedVisitorChanged()
                }
                setIsInitWebimVisitor(false)
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated, isFullyRegistered, isInitWebimVisitor])

    /**
     * TODO: Refactor me please
     */
    if (!isInitialized || (auth?.access_token && !profile?.user?.id)) {
        return (
            <div
                style={{
                    display: 'flex',
                    height: '100dvh',
                    width: '100vw',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Spinner />
            </div>
        )
    }

    if (import.meta.env.PROD) {
        return (
            <>
                <ModuleThemeSwitcher />
                <Outlet />
                {!location.hostname.includes('business.dostavkee') && <Devtools />}
            </>
        )
    }

    return (
        <Suspense fallback={<Spinner />}>
            <ModuleThemeSwitcher />
            <Outlet />
            <Devtools />
        </Suspense>
    )
}

function Root() {
    return (
        <>
            <ToastContainer
                closeOnClick
                autoClose={2000}
                draggable='touch'
                draggableDirection='y'
                limit={5}
                pauseOnFocusLoss={false}
                position='top-center'
                transition={Flip}
            />
            <ScrollRestoration />
            <RootView />
        </>
    )
}
