import React from 'react'
import { ErrorStruct } from '@dostavkee/contracts-console/entities'
import { IconCheck, IconClose, Input, InputPhone, Spinner } from '@dostavkee/web-ui'
import * as Sentry from '@sentry/react'
import { Controller, ControllerFieldState, useFormContext } from 'react-hook-form'

import { useEmployeeCheck } from '@/corpdrivee/entities/employee'
import { ERROR_CODE, ERROR_CODE_MESSAGE } from '@/shell/shared/config'
import { useDevtoolsOptions } from '@/shell/shared/model'

import { EmployeeFormValues } from './employee-form'

const PHONE_TEXT_MAP = new Map<number, string>([
    [
        ERROR_CODE.EMPLOYEE_ALREADY_EXISTS_ERROR_CODE,
        ERROR_CODE_MESSAGE.get(ERROR_CODE.EMPLOYEE_ALREADY_EXISTS_ERROR_CODE) as string,
    ],
    [
        ERROR_CODE.EMPLOYEE_DRIVEE_USER_NOT_FOUND_ERROR_CODE,
        ERROR_CODE_MESSAGE.get(ERROR_CODE.EMPLOYEE_DRIVEE_USER_NOT_FOUND_ERROR_CODE) as string,
    ],
    [
        ERROR_CODE.EMPLOYEE_DRIVEE_USER_ALREADY_BOUND_TO_COMPANY_ERROR_CODE,
        ERROR_CODE_MESSAGE.get(
            ERROR_CODE.EMPLOYEE_DRIVEE_USER_ALREADY_BOUND_TO_COMPANY_ERROR_CODE
        ) as string,
    ],
])

const getErrorMessage = (error: unknown) => {
    const errorStruct = error as ErrorStruct
    if (errorStruct?.error_code && PHONE_TEXT_MAP.has(errorStruct.error_code)) {
        return PHONE_TEXT_MAP.get(errorStruct.error_code)
    }

    Sentry.captureException(error)

    return 'Произошла неизвестная ошибка. Пожалуйста, обратитесь в службу технической поддержки'
}

export const EmployeeFormPhone = () => {
    const { mutateAsync, isPending } = useEmployeeCheck()
    const { control, formState, trigger } = useFormContext<EmployeeFormValues>()
    const { options } = useDevtoolsOptions()

    const defaultPhone = formState.defaultValues?.phone

    const getPostfixElement = (fieldState: ControllerFieldState, value: string) => {
        if (isPending) {
            return <Spinner />
        }

        if (fieldState.error?.message) {
            return <IconClose color='var(--extensions-text-and-icon-error)' />
        }

        if (value?.length === 11) {
            return <IconCheck color='var(--text-and-icon-accent)' />
        }

        return null
    }

    const validatePhone = async (value: string) => {
        if (defaultPhone && value === defaultPhone) {
            return true
        }

        if (!options.isPhoneValidationCorpDriveeDisabled && !value.startsWith('7')) {
            return 'Номер телефона должен начинаться с +7'
        }

        if (value?.length === 11) {
            try {
                const response = await mutateAsync({ data: { phone: value } })

                if (response.error) {
                    throw new Error(response.error)
                }

                return true
            } catch (error) {
                return getErrorMessage(error)
            }
        }

        return true
    }

    if (options.isPhoneValidationCorpDriveeDisabled) {
        return (
            <Controller
                control={control}
                name='phone'
                render={({ field, fieldState }) => (
                    <Input
                        autoComplete='off'
                        errorMessage={fieldState.error?.message}
                        id='phone'
                        isInvalid={fieldState.invalid}
                        label='Телефон'
                        {...field}
                    />
                )}
                rules={{
                    validate: validatePhone,
                }}
            />
        )
    }

    return (
        <Controller
            control={control}
            name='phone'
            render={({ field, fieldState }) => (
                <InputPhone
                    autoComplete='off'
                    disabled={defaultPhone?.length === 11}
                    errorMessage={fieldState.error?.message}
                    id='phone'
                    isInvalid={fieldState.invalid}
                    label='Телефон'
                    postfixEl={getPostfixElement(fieldState, field.value)}
                    {...field}
                    onChange={(value) => {
                        field.onChange(value)
                        if (value?.length === 11) {
                            trigger('phone')
                        }
                    }}
                />
            )}
            rules={{
                required: 'Обязательное поле',
                minLength: {
                    value: 11,
                    message: 'Некорректный номер телефона',
                },
                validate: validatePhone,
            }}
        />
    )
}
