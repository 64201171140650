import { ServiceOrderStage } from '@dostavkee/contracts-console/entities'

export const PROGRESS_STATUS_FLOW = [
    ServiceOrderStage.OrderStageSearching, // Поиск курьера
    ServiceOrderStage.OrderStageCourierAccepted, // Курьер в пути
    ServiceOrderStage.OrderStageCourierArrived, // Курьер на месте
    ServiceOrderStage.OrderStageCourierPickedUp, // В процессе
    ServiceOrderStage.OrderStageOrderCompleted, // Доставлен
]

export const PROGRESS_STATUS_WITH_FLOW = [
    ServiceOrderStage.OrderStageSearching, // Поиск курьера
    ServiceOrderStage.OrderStageCourierAccepted, // Курьер в пути
    ServiceOrderStage.OrderStageCourierArrived, // Курьер на месте
    ServiceOrderStage.OrderStageCourierPickedUp, // В процессе
]
