import { ServiceOrderStage } from '@dostavkee/contracts-console/entities'

export const STAGES_ALLOWED_CALL_COURIER = new Set([
    ServiceOrderStage.OrderStageCourierAccepted,
    ServiceOrderStage.OrderStageCourierArrived,
    ServiceOrderStage.OrderStageCourierPickedUp,
    ServiceOrderStage.OrderStageCourierCanceled,
] as ServiceOrderStage[])

export const STAGES_ALLOWED_CANCEL_ORDER = new Set([
    ServiceOrderStage.OrderStageCreated,
    ServiceOrderStage.OrderStageSearching,
    ServiceOrderStage.OrderStageCourierAccepted,
    ServiceOrderStage.OrderStageCourierArrived,
    ServiceOrderStage.OrderStageCourierPickedUp,
] as ServiceOrderStage[])

export const STAGES_ALLOWED_ARCHIVE_ORDER = new Set([
    ServiceOrderStage.OrderStageCourierCanceled,
    ServiceOrderStage.OrderStageCanceledByTimeout,
] as ServiceOrderStage[])

export const STAGES_ALLOWED_REPEAT_ORDER = new Set([
    ServiceOrderStage.OrderStageOrderCompleted,
    ServiceOrderStage.OrderStageCourierCanceled,
    ServiceOrderStage.OrderStageClientCanceled,
    ServiceOrderStage.OrderStageCanceledByTimeout,
    ServiceOrderStage.OrderStageClientArchived,
] as ServiceOrderStage[])

export const STAGES_ALLOWED_POLLING_LOCATION = new Set([
    ServiceOrderStage.OrderStageCourierAccepted,
    ServiceOrderStage.OrderStageCourierArrived,
    ServiceOrderStage.OrderStageCourierPickedUp,
] as ServiceOrderStage[])

export const STAGES_ALLOWED_POLLING_ORDER = new Set([
    ServiceOrderStage.OrderStageSearching,
    ServiceOrderStage.OrderStageCourierAccepted,
    ServiceOrderStage.OrderStageCourierArrived,
    ServiceOrderStage.OrderStageCourierPickedUp,
] as ServiceOrderStage[])

export const STAGES_ALLOWED_SHARE_ORDER = new Set([
    ServiceOrderStage.OrderStageCourierAccepted,
    ServiceOrderStage.OrderStageCourierArrived,
    ServiceOrderStage.OrderStageCourierPickedUp,
] as ServiceOrderStage[])

export const STAGES_ALLOWED_SHOW_ORDER_ALERT = new Set([
    ServiceOrderStage.OrderStageCourierCanceled,
    ServiceOrderStage.OrderStageCanceledByTimeout,
    ServiceOrderStage.OrderStageCourierArrived,
] as ServiceOrderStage[])

export const STAGES_ALLOWED_INCREASE_PRICE_ORDER = new Set([
    ServiceOrderStage.OrderStageSearching,
] as ServiceOrderStage[])
