import { ServiceOrderStage } from '@dostavkee/contracts-console/entities'
import { ChipVariant } from '@dostavkee/web-ui'

export const STAGE_TO_CHIP = new Map<ServiceOrderStage, ChipVariant>([
    [ServiceOrderStage.OrderStageCreated, ChipVariant.Warning],
    [ServiceOrderStage.OrderStageSearching, ChipVariant.Warning],
    [ServiceOrderStage.OrderStageCourierAccepted, ChipVariant.Info],
    [ServiceOrderStage.OrderStageCourierArrived, ChipVariant.InfoDeep],
    [ServiceOrderStage.OrderStageCourierPickedUp, ChipVariant.Success],
    [ServiceOrderStage.OrderStageOrderCompleted, ChipVariant.SuccessDeep],
    [ServiceOrderStage.OrderStageCourierCanceled, ChipVariant.Error],
    [ServiceOrderStage.OrderStageClientCanceled, ChipVariant.Error],
    [ServiceOrderStage.OrderStageCanceledByTimeout, ChipVariant.ErrorDeep],
    [ServiceOrderStage.OrderStageClientArchived, ChipVariant.Error],
])
