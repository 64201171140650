import React, { FC, useCallback, useEffect, useState } from 'react'
import { convertSeconds } from '@dostavkee/helpers'
import { useInterval } from '@dostavkee/react-hooks'
import dayjs from 'dayjs'

export interface ProgressStatusTimerProps {
    time?: string
}

export const ProgressStatusTimer: FC<ProgressStatusTimerProps> = ({ time }) => {
    const seconds = time ? dayjs(time).diff(dayjs(), 'seconds') : 0

    const [deltaSeconds, setDeltaSeconds] = useState(seconds > 0 ? seconds : 0)

    useEffect(() => {
        if (time) {
            const seconds = dayjs(time).diff(dayjs(), 'seconds')
            setDeltaSeconds(seconds > 0 ? seconds : 0)
        }
    }, [time])

    const handleCountdown = useCallback(() => {
        if (deltaSeconds === 0) {
            return
        }

        setDeltaSeconds(deltaSeconds - 1)
    }, [deltaSeconds])

    useInterval(handleCountdown, deltaSeconds === 0 ? null : 1000)

    return deltaSeconds > 0 ? <span>~{convertSeconds(deltaSeconds)}</span> : null
}
