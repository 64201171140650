export const convertSeconds = (seconds: number): string => {
    const minutes = Math.floor((seconds % 3600) / 60)
    const remainingSeconds = seconds % 60

    const minuteString = `${minutes} мин.`
    const secondString = `${remainingSeconds} сек.`

    if (minutes > 0) {
        return `${minuteString} ${secondString}`
    }

    return secondString
}
