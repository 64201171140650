import { ServiceOrderStage } from '@dostavkee/contracts-console/entities'

export const STAGE_TO_TEXT = new Map<ServiceOrderStage, string>([
    [ServiceOrderStage.OrderStageCreated, 'Запланирован'],
    [ServiceOrderStage.OrderStageSearching, 'Поиск курьера'],
    [ServiceOrderStage.OrderStageCourierAccepted, 'Курьер в пути'],
    [ServiceOrderStage.OrderStageCourierArrived, 'Курьер на месте'],
    [ServiceOrderStage.OrderStageCourierPickedUp, 'В процессе'],
    [ServiceOrderStage.OrderStageOrderCompleted, 'Доставлен'],
    [ServiceOrderStage.OrderStageCourierCanceled, 'Отменён курьером'],
    [ServiceOrderStage.OrderStageClientCanceled, 'Отменён'],
    [ServiceOrderStage.OrderStageCanceledByTimeout, 'Курьер не найден'],
    [ServiceOrderStage.OrderStageClientArchived, 'Отменён'],
])
