import React from 'react'
import { format, NumberFormatOptions, unformat, useNumberFormat } from '@react-input/number-format'

import { Input, InputProps } from '../input/input'

const OPTIONS: NumberFormatOptions & { locales?: Intl.LocalesArgument } = {
    locales: 'ru-RU',
    format: 'currency',
    currency: 'RUB',
    maximumFractionDigits: 0,
    signDisplay: 'never',
}

export interface InputMoneyProps extends Omit<InputProps, 'onChange'> {
    onChange: (value: number) => void
}

export const InputMoney: React.FC<InputMoneyProps> = React.forwardRef<
    HTMLInputElement,
    InputMoneyProps
>(({ onChange, value, ...props }, ref) => {
    const defaultValue = typeof value === 'number' ? format(value, OPTIONS) : null
    const inputRef = useNumberFormat(OPTIONS)
    const [formattedValue, setFormattedValue] = React.useState<string | null>(defaultValue)

    React.useEffect(() => {
        if (value) {
            setFormattedValue(format(value, OPTIONS))
        }
    }, [value])

    React.useEffect(() => {
        if (!ref) {
            return
        }

        if (typeof ref === 'function') {
            ref(inputRef.current)
        } else {
            ref.current = inputRef.current
        }
    }, [inputRef, ref])

    return (
        <Input
            ref={inputRef}
            autoComplete='off'
            inputMode='numeric'
            type='text'
            {...props}
            value={formattedValue}
            onChange={(event) => {
                const { value } = event.target
                const number = Number(unformat(value, 'ru-RU'))
                setFormattedValue(value)
                onChange(number)
            }}
            onPaste={(event) => {
                event.preventDefault()
                event.stopPropagation()

                const value = event.clipboardData.getData('text')
                const ceiledValue = Math.ceil(Number(value.replaceAll(/[^\d,.]/g, '')))

                setFormattedValue(format(ceiledValue, OPTIONS))
                onChange(ceiledValue)
            }}
        />
    )
})

InputMoney.displayName = 'InputMoney'
