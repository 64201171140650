import React, { FC, Suspense, useState } from 'react'
import { Button, Checkbox, Modal } from '@dostavkee/web-ui'

import { useDevtoolsOptions } from '../../model'
import styles from './devtools.module.scss'

const TanStackRouterDevtools = import.meta.env.PROD
    ? () => null
    : React.lazy(() =>
          import('@tanstack/router-devtools').then((module) => ({
              default: module.TanStackRouterDevtools,
          }))
      )

const TanStackReactQueryDevtools = import.meta.env.PROD
    ? () => null
    : React.lazy(() =>
          import('@tanstack/react-query-devtools').then((module) => ({
              default: module.ReactQueryDevtools,
          }))
      )

const INITIAL_OPTIONS = {
    isPhoneValidationCorpDriveeDisabled: false,
    isTanstackRouterEnabled: false,
    isTanstackQueryEnabled: false,
    isRecaptchaDisabled: false,
}

export const Devtools: FC = () => {
    const [isOpen, setIsOpen] = useState(false)

    const { options } = useDevtoolsOptions()

    return (
        <>
            <div className={styles['devtools']} role='button' onClick={() => setIsOpen(true)}>
                Версия: v.{APP_VERSION}
            </div>
            {options.isTanstackQueryEnabled && (
                <Suspense>
                    <TanStackReactQueryDevtools buttonPosition='bottom-right' position='bottom' />
                </Suspense>
            )}
            {options.isTanstackRouterEnabled && (
                <Suspense>
                    <TanStackRouterDevtools
                        position='bottom-right'
                        toggleButtonProps={{ style: { right: 72, bottom: 18 } }}
                    />
                </Suspense>
            )}

            {isOpen && <DevtoolsModal onClose={() => setIsOpen(false)} />}
        </>
    )
}

Devtools.displayName = 'Devtools'

const DevtoolsModal = ({ onClose }: { onClose: () => void }) => {
    const { options, setOptions } = useDevtoolsOptions()

    const [localOptions, setLocalOptions] = useState(options)

    const onSave = () => {
        setOptions(localOptions)
        onClose()
    }

    const onReset = () => {
        setOptions(INITIAL_OPTIONS)
        onClose()
    }

    return (
        <Modal isOpen className={styles['devtools__modal']} onClose={onClose}>
            <Modal.Header onClose={onClose}>Настройки разработчика</Modal.Header>
            <Modal.Body>
                <div className={styles['devtools__modal-body']}>
                    <Checkbox
                        checked={localOptions.isPhoneValidationCorpDriveeDisabled}
                        onChange={() =>
                            setLocalOptions({
                                ...localOptions,
                                isPhoneValidationCorpDriveeDisabled:
                                    !localOptions.isPhoneValidationCorpDriveeDisabled,
                            })
                        }
                    >
                        Отключить валидацию номера телефона в CorpDrivee
                    </Checkbox>
                    <Checkbox
                        checked={localOptions.isTanstackRouterEnabled}
                        onChange={() =>
                            setLocalOptions({
                                ...localOptions,
                                isTanstackRouterEnabled: !localOptions.isTanstackRouterEnabled,
                            })
                        }
                    >
                        Инструменты разработчика tanstack-router
                    </Checkbox>
                    <Checkbox
                        checked={localOptions.isTanstackQueryEnabled}
                        onChange={() =>
                            setLocalOptions({
                                ...localOptions,
                                isTanstackQueryEnabled: !localOptions.isTanstackQueryEnabled,
                            })
                        }
                    >
                        Инструменты разработчика tanstack-query
                    </Checkbox>
                    <Checkbox
                        checked={localOptions.isRecaptchaDisabled}
                        onChange={() =>
                            setLocalOptions({
                                ...localOptions,
                                isRecaptchaDisabled: !localOptions.isRecaptchaDisabled,
                            })
                        }
                    >
                        Отключить проверку reCaptcha
                    </Checkbox>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button size='small' variant='secondary' onClick={onReset}>
                    Сбросить
                </Button>
                <Button size='small' onClick={onSave}>
                    Сохранить
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
