export enum PaymentStatusCode {
    NEW = 'NEW',
    FORM_SHOWED = 'FORM_SHOWED',
    AUTHORIZING = 'AUTHORIZING',
    '3DS_CHECKING' = '3DS_CHECKING',
    '3DS_CHECKED' = '3DS_CHECKED',
    AUTHORIZED = 'AUTHORIZED',
    CONFIRMING = 'CONFIRMING',
    CONFIRMED = 'CONFIRMED',
    REVERSING = 'REVERSING',
    PARTIAL_REVERSED = 'PARTIAL_REVERSED',
    REVERSED = 'REVERSED',
    REFUNDING = 'REFUNDING',
    PARTIAL_REFUNDED = 'PARTIAL_REFUNDED',
    REFUNDED = 'REFUNDED',
    СANCELED = 'СANCELED',
    DEADLINE_EXPIRED = 'DEADLINE_EXPIRED',
    REJECTED = 'REJECTED',
    AUTH_FAIL = 'AUTH_FAIL',
}

export type PAYMENT_STATUS = 'success' | 'fail'

export type PAYMENT_METHOD = 'card' | 'invoice' | 'sbp'
