import React from 'react'

import { InputMessage } from '../input-message'
import { RadioButton } from './radio-button'
import styles from './radio-group.module.scss'

export interface RadioComposition {
    Button: typeof RadioButton
}

export interface RadioGroupProps {
    children: React.ReactNode
    label: string
    errorMessage?: string
    hint?: string | React.ReactNode
}

export const RadioGroup: React.FC<RadioGroupProps> & RadioComposition = ({
    label,
    children,
    errorMessage,
    hint,
    // ...props
}: RadioGroupProps) => {
    return (
        <fieldset className={styles['radio-group']}>
            <legend className={styles['radio-group__label']}>{label}</legend>
            {children}
            {errorMessage ? (
                <InputMessage variant='error'>{errorMessage}</InputMessage>
            ) : typeof hint === 'string' ? (
                <InputMessage variant='info'>{hint}</InputMessage>
            ) : (
                hint
            )}
        </fieldset>
    )
}

RadioGroup.displayName = 'RadioGroup'
RadioGroup.Button = RadioButton
