import React from 'react'
import clsx from 'clsx'

import styles from './checkbox.module.scss'

export interface CheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
    isIndeterminate?: boolean
    isInvalid?: boolean
    checkboxSize?: 'small' | 'medium' | 'large'
    children?: React.ReactNode
}

export const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
    (
        {
            children,
            checked,
            isIndeterminate,
            checkboxSize = 'medium',
            disabled,
            isInvalid,
            ...props
        },
        ref
    ) => {
        return (
            <label
                role='checkbox'
                className={clsx({
                    [styles['checkbox']]: true,
                    [styles[`checkbox-size_${checkboxSize}`]]: true,
                    [styles['checkbox_disabled']]: disabled,
                })}
            >
                <div
                    className={clsx({
                        [styles['checkbox__container']]: true,
                        [styles[`checkbox__container_size_${checkboxSize}`]]: true,
                    })}
                >
                    <input
                        ref={ref}
                        aria-disabled={disabled}
                        checked={checked}
                        className={styles['checkbox__input']}
                        disabled={disabled}
                        type='checkbox'
                        {...props}
                    />
                    <div
                        className={clsx({
                            [styles['checkbox__square']]: true,
                            [styles['checkbox__square_checked']]: checked || isIndeterminate,
                            [styles['checkbox__square_disabled']]: disabled,
                            [styles['checkbox__square_invalid']]: isInvalid,
                        })}
                    >
                        {checked && <CheckedIcon />}
                        {isIndeterminate && <IndeterminateIcon />}
                    </div>
                    {children && (
                        <span
                            className={clsx({
                                [styles['checkbox__text']]: true,
                                [styles[`checkbox__text_size_${checkboxSize}`]]: true,
                                [styles['checkbox__text_disabled']]: disabled,
                            })}
                        >
                            {children}
                        </span>
                    )}
                </div>
            </label>
        )
    }
)

Checkbox.displayName = 'Checkbox'

const CheckedIcon = () => {
    return (
        <svg
            fill='none'
            height='11'
            viewBox='0 0 12 11'
            width='12'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                clipRule='evenodd'
                d='M11.6633 0.779661C12.0766 1.18672 12.1147 1.88896 11.7483 2.34817L5.54318 10.1259C5.3649 10.3494 5.11316 10.4837 4.84472 10.4986C4.57629 10.5135 4.31376 10.4077 4.11633 10.2051L0.321463 6.31012C-0.0842237 5.89374 -0.109305 5.19077 0.265442 4.74001C0.64019 4.28925 1.27286 4.26138 1.67854 4.67776L4.72364 7.80316L10.2517 0.874076C10.618 0.414873 11.25 0.372602 11.6633 0.779661Z'
                fill='white'
                fillRule='evenodd'
            />
        </svg>
    )
}

const IndeterminateIcon = () => {
    return (
        <svg
            fill='none'
            height='5'
            viewBox='0 0 14 5'
            width='14'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M12 2.5H6.04731H2'
                stroke='white'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth='3.5'
            />
        </svg>
    )
}
