import { useAtom } from 'jotai'
import { atomWithStorage, createJSONStorage } from 'jotai/utils'

const DEVTOOLS_STORAGE_KEY = 'devtools'

interface DevtoolsOptions {
    isPhoneValidationCorpDriveeDisabled: boolean
    isTanstackRouterEnabled: boolean
    isTanstackQueryEnabled: boolean
    isRecaptchaDisabled: boolean
}

const devtoolsStorage = createJSONStorage<DevtoolsOptions>(() => localStorage)

const devtoolsAtom = atomWithStorage<DevtoolsOptions>(
    DEVTOOLS_STORAGE_KEY,
    {
        isPhoneValidationCorpDriveeDisabled: false,
        isTanstackRouterEnabled: false,
        isTanstackQueryEnabled: false,
        isRecaptchaDisabled: false,
    },
    devtoolsStorage,
    {
        getOnInit: true,
    }
)

export const useDevtoolsOptions = () => {
    const [options, setOptions] = useAtom(devtoolsAtom)

    return { options, setOptions }
}
